<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw1>
    <v-col cols="12">
      <v-card>
        <v-card-title primary-title>
          <span
            v-t="'doc.other.title'"
            class="text-h5"/>
        </v-card-title>
        <v-card-text>
          <v-list two-line>
            <v-list-item
              v-for="(document, idx) in docs"
              :key="`document-${idx}`">
              <v-list-item-avatar>
                <a
                  :href="docLink (document.file)"
                  target="_blank"
                  :title="document.description">
                  <v-icon large v-text="mimeTypeToIcon (document.mimeType)"/>
                </a>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <a
                    :href="docLink (document.file)"
                    target="_blank"
                    v-text="document.description"/>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ document.version }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import BaseLayout from '@/app/core/components/BaseLayout'
  import docs from '@/assets/docs/doclist.json'

  export default {
    components: {
      BaseLayout
    },

    data () {
      return {
        docs,
        fileTypes: {
          'application/pdf': 'pdf',
          'application/x-compressed': 'archive',
          'application/x-gzip': 'archive',
          'application/x-tar': 'archive',
          'application/xml': 'xml',
          'application/zip': 'archive',
          'text/csv': 'csv',
          'text/html': 'html',
          'text/plain': 'txt',
          'text/xml': 'xml'
        }
      }
    },

    methods: {
      /**
       * Get the corresponding icon identifier to the given MIME type.
       *
       * @param mimeType        the MIME type
       * @return {string}      the icon identifier
       */
      mimeTypeToIcon (mimeType) {
        return `$vuetify.icons.${this.fileTypes[mimeType] || 'generic'}`
      },

      /**
       * Get the full path to the given document filename.
       *
       * @param filename      the document file
       * @return {*}          the document path
       */
      docLink (filename) {
        return require (`@/assets/docs/files/${filename}`)
      }
    }
  }
</script>
